const topMain = () => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {



    const secMain = document.querySelector('#jsSecMain');
    // const copy1 = secMain.querySelector('.js-copy-1');
    const copy2 = secMain.querySelector('.js-copy-2');

    setTimeout(() => {
      // copy1.classList.add('act')
      copy2.classList.add('act')
    }, 400);

    setTimeout(() => {
      // copy2.classList.add('act')
    }, 800);



  }
}
export default topMain;